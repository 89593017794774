import React,{useEffect,useState} from 'react'
import { GoogleMap, useJsApiLoader,TrafficLayer } from '@react-google-maps/api';
import {withFirebase} from '../Firebase'
import LocationMarker from './location_marker'
import {onValue,ref} from 'firebase/database'

const containerStyle = {
  width: '100vw',
  height: '100vh'
};




function GMap({firebase}) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDqLAok8VFxErXTvGp_uoWZFRygnOeILoQ"
  })

  const [center,setCenter] = useState({
    lat: 23.44,
    lng: 90.444,
  })

  const device = JSON.parse(localStorage.getItem("Device"));


  useEffect(() => {

    let deviceRef = ref(firebase.db,"/devices/"+device.id+"/geo")

    return onValue(deviceRef,snapshot=>{
         let geo = snapshot.val()
  
      if(center.lat!== geo.lat || center.lng !==geo.lng){
        setCenter(geo)
      }
    },{onlyOnce:true})
  
    
  }, [firebase]);

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={17}
      >
        <LocationMarker device={device} />
        <TrafficLayer />
      </GoogleMap>
  ) : <></>
}

export default withFirebase(React.memo(GMap))
