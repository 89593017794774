import React,{useEffect} from "react";
import { Box } from "@mui/material";
import Login from "./login";
import Map from "./map";
import {useNavigate} from 'react-router-dom'




const Home = () => {
  const navigate = useNavigate()
  const device = JSON.parse(localStorage.getItem("Device"));


  useEffect(()=>{

    if(device){
      navigate('/map')
    }else{
      navigate('/login')
    }

  },[])


  return <Box></Box>;
};



export default Home
