import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function BasicDatePicker({value,setValue}) {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Select Report Date"
        // inputFormat="dd/MM/yyyy"
        value={value}
        onChange={(newValue) => {
          setValue(new Date(newValue));
        }}
        renderInput={(params) => {
          return <TextField {...params} />
        }}
      />
    </LocalizationProvider>
  );
}