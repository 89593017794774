import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Firebase, { FirebaseContext } from "./Firebase";

import Home from "./component/home";
import Login from "./component/login";
import Map from "./component/map";
import DailyReport from "./component/DailyReport/dailyReport";
import MonthlyReport from "./component/report";
import TravelReport from "./component/travel_report";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/login",
    element: <Login/>,
  },
  {
    path: "/map",
    element: <Map/>,
  },
  {
    path: "/dailyreport",
    element: <DailyReport/>,
  },

  {
    path: "/report",
    element: <MonthlyReport/>,
  },
  {
    path: "/travel-report",
    element: <TravelReport/>,
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
        <FirebaseContext.Provider value={new Firebase()}>
        <RouterProvider router={router} />
       </FirebaseContext.Provider>
  </React.StrictMode>
);

reportWebVitals();
