/* global google */
import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Polyline,
  Marker,
} from "@react-google-maps/api";
import { DAILY_LOCATIONS_URL } from "../utils/urls";
import axios from "axios";
import * as geolib from "geolib";
import anime from "animejs/lib/anime.es";

const containerStyle = {
  width: "100vw",
  height: "100%",
};

const DailyPolyline = ({ locations }) => {
  const options = {
    strokeColor: "#000000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#555555",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  const getPath = () => {
    let path = [...locations].map((x) => {
      return { lat: x.geo.lat, lng: x.geo.lng };
    });

    return path;
  };

  return <Polyline path={getPath()} options={options} />;
};

const TravelMap = ({ date, device, animParam, setState }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDqLAok8VFxErXTvGp_uoWZFRygnOeILoQ",
  });

  const animRef = useRef(null);

  const oldDate = useRef();

  const animProgress = useRef(0);

  const [myState, setMyState] = useState({
    center: { lat: 23.44, lng: 90.444 },
    locations: [],
    geo: {},
    zoom: 17,
    // duration:500
  });

  useEffect(() => {
    const callApi = () => {
      axios
        .post(DAILY_LOCATIONS_URL, {
          device_time: date,
          id: device.id,
          device_type: device.vehicle_type,
        })
        .then((response) => {
          // console.log(response.data)
          if (response.data.length > 0) {
            let oldState = { ...myState };
            oldState.locations = response.data;
            oldState.geo.lat = response.data[0].geo.lat;
            oldState.geo.lng = response.data[0].geo.lng;
            oldState.geo.rotation = 0;

            // setLocations(response.data)
            let latlongs = [...response.data].map((x) => {
              return { latitude: x.geo.lat, longitude: x.geo.lng };
            });

            if (latlongs.length > 0) {
              let tempCen = geolib.getCenterOfBounds(latlongs);
              oldState.center = {
                lat: tempCen.latitude,
                lng: tempCen.longitude,
              };
              // setCenter()
            }

            setMyState(oldState);
          }

          oldDate.current = date;
        })
        .catch((err) => console.log(err));
    };

    if (!oldDate.current) {
      callApi();
    } else if (oldDate.current !== date) {
      callApi();
    } else {
      console.log("Api Not Called");
    }

    // setTimeout(()=>{

    // },1000)
  }, [date]);

  const getRotation = () => {
    return myState.geo.rotation;
  };

  useEffect(() => {
    if (myState.locations.length > 0) {
      let target = {
        lat: myState.locations[0].geo.lat,
        lng: myState.locations[0].geo.lng,
        rotation: 0,
        speed: myState.locations[0].geo.speed,
        acc: myState.locations[0].geo.acc,
        time:
          myState.locations[0].date.hour * 3600 +
          myState.locations[0].date.minute * 60 +
          myState.locations[0].date.second,
      };

      if (animRef.current) {
        animRef.current.pause();
      }

      animRef.current = anime.timeline({
        targets: target,
        easing: "linear",
        duration: animParam.duration,
        autoplay: false,
        update: (anim) => {
          animProgress.current = anim.progress;
          setState({ speed: parseInt(target.speed), acc: target.acc,time:parseInt(target.time) });
          let oldState = { ...myState };
          oldState.geo.lat = Number(target.lat.toFixed(7));
          oldState.geo.lng = Number(target.lng.toFixed(7));
          setMyState(oldState);
        },
      });

      for (let i = 0; i < myState.locations.length - 1; i++) {
        animRef.current.add({
          lat: [myState.locations[i].geo.lat, myState.locations[i + 1].geo.lat],
          lng: [myState.locations[i].geo.lng, myState.locations[i + 1].geo.lng],
          speed: [
            myState.locations[i].geo.speed,
            myState.locations[i + 1].geo.speed,
          ],
          time: [
            myState.locations[i].date.hour * 3600 +
              myState.locations[i].date.minute * 60 +
              myState.locations[i].date.second,
            myState.locations[i+1].date.hour * 3600 +
              myState.locations[i+1].date.minute * 60 +
              myState.locations[i+1].date.second,
          ],
          // acc:[myState.locations[i].geo.acc,myState.locations[i+1].geo.acc],

          begin: () => {
            target.acc = myState.locations[i].geo.acc;
            let rotation = geolib.getGreatCircleBearing(
              {
                latitude: myState.locations[i].geo.lat,
                longitude: myState.locations[i].geo.lng,
              },
              {
                latitude: myState.locations[i + 1].geo.lat,
                longitude: myState.locations[i + 1].geo.lng,
              }
            );

            let newState = { ...myState };
            myState.geo.rotation = rotation;
            setMyState(newState);
          },
        });
      }
    }

    if (animRef.current) {
      if (animProgress.current > 0) {
        animRef.current.pause();
        animRef.current.seek(
          parseInt((animRef.current.duration * animProgress.current) / 100)
        );
        animRef.current.play();
      }
    }
  }, [myState.locations, animParam.duration]);

  useEffect(() => {
    console.log("Button Clicked");

    if (animParam.buttonClick === 1) {
      if(animRef.current){
        animRef.current.play();
      }
      
    } else if (animParam.buttonClick === 2) {
      if(animRef.current){
        animRef.current.pause();
      }
    } else if (animParam.buttonClick === 3) {
      if(animRef.current){
        animRef.current.restart();
      }
      
    }
  }, [animParam.buttonClick]);

  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={myState.center}
        zoom={myState.zoom}
      >
        <DailyPolyline locations={myState.locations} />

        {myState.locations.length > 0 && (
          <Marker
            alt="Vehicle Location"
            position={{ lat: myState.geo.lat, lng: myState.geo.lng }}
            // clickable={true}
            // onClick={handleMarkerClick}
            icon={{
              path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805z",
              fillColor: "black",

              fillOpacity: 0.9,
              strokeColor: "green",
              strokeWeight: 2,
              rotation: getRotation(),
              anchor: new google.maps.Point(25, 25),
            }}
          />
        )}
      </GoogleMap>
    )
  );
};

export default TravelMap;
