import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TitleBar from "./title_bar";
import TravelMap from "./travel-map";

import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import { green,red,blue,blueGrey, grey,orange } from "@mui/material/colors";

const TravelReport = () => {
  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();

  const [state, setState] = useState({speed:0,acc:"OFF",time:0});

  const [animParam, setAnimParam] = useState({
    duration: 500,
    buttonClick: -1,
    isPlaying: false,
  });

  const device = JSON.parse(localStorage.getItem("Device"));


  const chooseSpeedColor=speed=>{
    if(speed<20){
      return blue[500]
    }else if(speed<60){
      return green[500]
    }else{
      return red[500]
    }
  }

  const accColor=acc=>{
    if(acc==="OFF"){
      return red[700]
    }else{
      return green[700]
    }
  }

  const handlePlay = (e) => {
    let p = { ...animParam };
    p.buttonClick = 1;
    p.isPlaying = true;
    setAnimParam(p);
  };

  const slowAnim = (e) => {
    let p = { ...animParam };
    p.duration = parseInt(p.duration * 2);
    setAnimParam(p);
  };

  const fastAnim = (e) => {
    let p = { ...animParam };

    p.duration = parseInt(p.duration / 2);
    setAnimParam(p);
  };

  const handlePause = (e) => {
    let p = { ...animParam };
    p.isPlaying = false;
    p.buttonClick = 2;
    setAnimParam(p);
  };

  const handleRestart = (e) => {
    let p = { ...animParam };
    p.isPlaying = true;
    p.buttonClick = 3;
    setAnimParam(p);
  };

  useEffect(() => {
    if (!device) {
      navigate("/login");
    }
  }, []);


  const myTime = time=>{
    let hr = parseInt(time/3600)
    let rem = time%3600
    let min = parseInt(rem/60)
    let sec = rem%60

    return hr.toString().padStart(2,0)+":"+min.toString().padStart(2,0)+":"+sec.toString().padStart(2,0)
  }

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TitleBar
        title="Travel Report"
        calendarVisible={true}
        date={date}
        setDate={setDate}
      />
      <div style={{ position: "fixed", left: 0, right: 0, top: 64, bottom: 0 }}>
        <TravelMap
          date={date}
          device={device}
          animParam={animParam}
          setState={setState}
        />
      </div>

      <div
        style={{
          position: "fixed",
          width: "100%",
          left: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between",gap:10,marginBottom:10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: 70,
                height: 70,
                borderRadius: 35,
                backgroundColor: chooseSpeedColor(state.speed),
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>{state.speed} kmph</p>
            </div>

            <p
              style={{
                borderRadius: 10,
                backgroundColor: orange[200],
                padding: 4,
                textAlign: "center",
              }}
            >
              Speed
            </p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: 100,
                height: 70,
                borderRadius:5,
                backgroundColor: grey[700],
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>{myTime(state.time)}</p>
            </div>

            <p
              style={{
                borderRadius: 10,
                backgroundColor: orange[200],
                padding: 4,
                textAlign: "center",
              }}
            >
              Time
            </p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: 70,
                height: 70,
                borderRadius: 35,
                backgroundColor: accColor(state.acc),
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>{state.acc}</p>
            </div>

            <p
              style={{
                borderRadius: 10,
                backgroundColor: orange[200],
                padding: 4,
                textAlign: "center",
              }}
            >
              ACC
            </p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: 10,
            backgroundColor: "white",
            padding: 10,
          }}
        >
          <IconButton
            onClick={slowAnim}
            color="primary"
            aria-label="slow anim"
            component="label"
          >
            <FastRewindIcon />
          </IconButton>

          {animParam.isPlaying ? (
            <IconButton
              onClick={handlePause}
              color="primary"
              aria-label="pause"
              component="label"
            >
              <PauseIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={handlePlay}
              color="primary"
              aria-label="play"
              component="label"
            >
              <PlayArrowIcon />
            </IconButton>
          )}

          <IconButton
            onClick={handleRestart}
            color="primary"
            aria-label="restart"
            component="label"
          >
            <RestartAltIcon />
          </IconButton>

          <IconButton
            onClick={fastAnim}
            color="primary"
            aria-label="fast"
            component="label"
          >
            <FastForwardIcon />
          </IconButton>

          {/* <button onClick={slowAnim}>slow</button>
      <button onClick={handlePlay}>play</button>
      <button onClick={handlePause}>pause</button>
      <button onClick={handleRestart}>restart</button>
      <button onClick={fastAnim}>fast</button> */}
        </div>
      </div>

      {/* <div style={{position:"fixed",left:10,bottom:10,width:100,height:100,borderRadius:50,backgroundColor:'red',display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
    
      </div> */}
    </div>
  );
};

export default TravelReport;
