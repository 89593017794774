import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import TimelineIcon from "@mui/icons-material/Timeline";
import BarChartIcon from "@mui/icons-material/Report";
import RouteIcon from '@mui/icons-material/Route';
// import NavBar from "./nav";
// import TitleBar from "./title_bar";
import Googlemap from "./gmap";
import TitleBar from "./title_bar";

import {useNavigate} from 'react-router-dom'

const Map = () => {
  const device = JSON.parse(localStorage.getItem("Device"));
  const navigate = useNavigate();
  const monthlyClicked = () => {
    navigate("/report");
  };
  const dailyClicked = () => {
    navigate("/dailyreport");
  };

  const travelReportClick=e=>{
    navigate("/travel-report")
  }

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TitleBar title="Live Tracking" />
      <div style={{ position: "fixed", left: 0, right: 0, top: 64, bottom: 0 }}>
        <Googlemap />
      </div>

      <div
        style={{
          position: "absolute",
          right: 10,
          bottom: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          variant="contained"
          startIcon={<BarChartIcon />}
          
          onClick={dailyClicked}
        >
          Daily Report
        </Button>

        <Button  style={{ marginBottom: 20,marginTop:20 }}onClick={monthlyClicked} variant="contained" startIcon={<TimelineIcon />}>
          Monthly Report
        </Button>

        <Button onClick={travelReportClick} variant="contained" startIcon={<RouteIcon />}>
          Travel Report
        </Button>
      </div>
    </div>
  );
};

export default Map;
